.foot {
    position: relative;
    width: 100%;
    height: 500px;
    box-sizing: border-box;
    background: #1A1A1A;
    color: white;
    position: relative;
}

.foot_title {
    width: 196px;
margin: auto;
font-size: 28px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #FFFFFF;
margin-bottom: 5px;
}
.foot_title_text{
    width: 1100px;
    height: 48px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    line-height: 48px;
    margin: auto;
    }
    .foot_xhx{
        width: 1200px;
        height: 28px;
        border-bottom: 1px solid #FFFFFF;
        text-align: center;
        margin: auto;
        position: relative;
    }
    .foot_info{
        position: relative;
        height: 50px;
        font-size: 28px;
        width: 1100px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: center;
        margin: auto;
        margin-top: 20px;
    }
    .foot_QCode{
        width: 300px;
        height: 200px;
        display: flex;
        flex-direction: row;
        margin: auto;
        margin-top: 30px;
    }
    .foot_QCode img{
        width: 159px;
        height: 159px;
        margin-left: 20px;
    }
.foot_phone{
    position: absolute;
    font-size: 28px;
    top: 172px;
    height: 27px;
}
.foot_address{
    position: absolute;
    width: 500px;
height: 27px;
font-size: 28px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #FFFFFF;
line-height: 48px;
top: 172px;
height: 27px;
left: 412px;
}
.foot_text1{
    width: 647px;
height: 19px;
font-size: 20px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #FFFFFF;
line-height: 24px;
margin: auto;
text-align: center;
}
.foot_text2{
    
height: 19px;
font-size: 20px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #999899;
line-height: 60px;
text-align: center;
margin: auto;
}
.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.foot_box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
}

.foot_box_left {


    color: white;
}

.foot_box_left_img {
    width: 150px;
    height: 50px;
}

.foot_box_left_text {
    padding: 15px;
    min-width: 200px;
    max-width: 600px;
    min-height: 200px;
    color: #ffffffad;
    font-size: 16px;
    font-family: 宋体;
}

.foot_box_left_img img {
    width: 100%;
    height: 100%;
}

.foot_box_right {
    color: #ffffffad;
    font-size: 16px;
    font-family: 宋体;
}

.foot_box_right div:first-child {
    padding: 0px 25px 25px 25px;
    color: #FFC000;
    font-size: 20px;
    font-weight: bold;
}

.foot_box_bottom {
    text-align: center;
    margin: auto;
    color: gray;
    display: flex;
    flex-direction: row;
    justify-content: center
}

.foot_box_bottom div {
    padding: 25px;
}

/* 联系方式 */
.foot_contact_us {
    width: 1100px;
    margin: 10px auto 40px auto;
    display: flex;
    justify-content: space-between;
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
}

/* 二维码 */
.foot_qr_box {
    margin: 10px auto 40px auto;
    width: 350px;
    display: flex;
    justify-content: space-between;
}

.foot_qr_item {
    width: 160px;
    height: 160px;
}

.foot_qr_desc {
    margin-bottom: 40px;
    text-align: center;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
}

/* 备案号 */
.foot_icp_box {
    text-align: center;
}

.foot_icp {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999899;
    line-height: 60px;
}