@import '~antd/dist/antd.css';

@font-face {
    font-family: Source Han Sans CN;
    src: url('../src/static/font/SourceHanSansCN-Regular.otf')
}

.App {
    background-color: #000000 !important;
    height: 100vh;
}

.layout {
    min-height: 100vh;
    background-color: #000000 !important;
}

.layout_head {
    height: 90px;
    background-color: #000000 !important;
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
    box-sizing: border-box;
}

.layout_content {
    min-height: 70vh;
}

.layout_foot {
    min-height: 20vh;
    padding: 28px;
    width: 100%;
}

.ant-layout-footer {
    background: #1A1A1A;
}