.logo_box img {
    height: 53px;
    width: 153px;
}



.box {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    z-index: 1000;
}

.logo_text {
    font-size: 30px;
    font-weight: bold;
    left: 240px;
    line-height: 60px;
    min-width: 212px;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
}

.container:hover .hover-background {
    display: block;
}

.container .one {
    position: relative;
    z-index: 11;
}

.container .aa {
    position: relative;
}

.hover-product:hover .product-nav-list {
    display: block;
}

.product-nav-list {
    display: none;
    position: absolute;
    text-align: center;
    width: 100px;
    left: 50%;
    transform: translateX(-50%);
    top: 80px;
}



.product-nav-item {
    font-size: 18px;
    height: 40px;
    line-height: 40px;
}

.product-nav-item a {
    color: rgb(131, 129, 129);
}

.product-nav-item a:hover {
    color: #FFF;
}



.container .aa::after {
    display: none;
    position: absolute;
    content: '';
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    height: 4px;
    width: 100px;
    background-color: #FFF;
}

.click-bottom-line {
    position: absolute;
    content: '';
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    height: 4px;
    width: 100px;
    background-color: #FFF;
}

.container .aa:hover::after {
    display: block;
}


.one {
    position: relative;
}

.menu-line {
    color: #fff;
}

.aa {
    display: block;
    overflow: hidden;
    text-align: center;
    color: #c1c1c1;
    padding: 0px 5px 5px 5px;
    font-size: 24px;
    font-family: 'Source Han Sans CN';
    font-weight: 500;
    color: #FFFFFF;
    line-height: 90px;

}

.product-nav-list:hover + .aa::after {
    display: block;
}

.aa:hover {
    color: #fff;
}

/* @media (max-width: 767px) {
    .top {
        padding: 20px 30px;
        height: 120px;
        position: relative;
        transition: all 300ms ease-in-out 0s;
        display: flex;
        flex-direction: column;
    }

    .logo_text {
        font-size: 18px;
        font-weight: bold;
        left: 240px;
        line-height: 30px;
    }

    .container {
        width: 400px;
    }

    .aa {
        display: block;

        overflow: hidden;

        text-align: center;
        color: #FFFFFF;
        font-size: 12px;
        padding: 0px 5px 5px 5px;
        width: auto;
    }
} */

@media (min-width: 868px) {
    .top {
        padding: 20px;
        height: 120px;
        position: relative;
        transition: all 300ms ease-in-out 0s;
        display: flex;
        flex-direction: row;
    }

    .logo_text {
        font-size: 30px;
        margin-left: 30px;
        font-weight: bold;
        left: 240px;
        line-height: 60px;
    }

    .container {
        width: 1200px;
    }

    .aa {
        display: block;
        overflow: hidden;
        text-align: center;
        color: #FFFFFF;
        padding: 0px 5px 5px 5px;
        width: 140px;
        font-size: 24px;
    }
}

.hover-background {
    display: none;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    height: 300px;
    background-image: linear-gradient(-180deg,#000 10%, transparent);
    z-index: 10;
}

.logo_box_black {
    display: none;
}